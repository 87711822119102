import React, { forwardRef, LegacyRef, useEffect, useState } from 'react'
import { Text } from '@chakra-ui/layout'
import { ChakraProps } from '@chakra-ui/system'
import { Box } from '../Box'
import { Divider } from '../Divider'
import { Meme } from '../../types'
import SeeAll from '../../icons/SeeAll'
import { Avatar } from '../Avatar'
import useShowTime from '../../utils/common'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useMediaQuery
} from '@chakra-ui/react'
import { fetcher, fetchMemes } from '@krasia/core/fetchers'
import { Info } from '../../icons'
import router from 'next/router'
import { LazyLoadImage } from 'react-lazy-load-image-component'

/* eslint-disable */
export type MemeModalProps = {
  list: Meme[]
  title: string
  icon?: React.ReactNode
  visible: boolean
  onClose: (status?: boolean) => void
  author?: string
  currentPage?: number
}
/* eslint-enable */

export type MemeSectionProps = ChakraProps &
  React.HTMLAttributes<HTMLElement> & {
    icon?: React.ReactNode
    iconImage?: string
    title: React.ReactNode
    list: Meme[]
  }

export const MemeItem = (meme: Meme) => {
  const postDate = useShowTime(meme.date)
  return (
    <Box display={'flex'} flexDirection='row' alignItems={'top'}>
      {meme.author && meme.author.avatar && (
        <Avatar
          size={8}
          width={{ base: '32px' }}
          height={{ base: '32px' }}
          mr='8px'
          src={`${meme.author.avatar}!square.small`}
          alt={meme.author.name}
        />
      )}
      <Box flex={1}>
        <Box display={'flex'} alignItems={'baseline'} mb={{ base: '10px' }}>
          {meme.author && meme.author.name && (
            <Box color={'sectionTitle'} fontSize={{ base: '16px' }}>
              {meme.author.name}
            </Box>
          )}
          <Box ml='8px' fontSize='12px' color='gray.500'>
            {postDate}
          </Box>
        </Box>
        <Box pb={'20px'}>
          <Box textStyle='excerpt' fontSize={'14px'}>
            {meme.meme && <span dangerouslySetInnerHTML={{ __html: meme.meme }}></span>}
          </Box>
          <Box
            as='a'
            href={`/${meme.post.slug}`}
            target='_blank'
            title={`阅读「${meme.post.title.rendered}」`}
            backgroundColor={'rgba(0,0,0,0.10)'}
            // borderRadius={'8px'}
            mt={'12px'}
            p={'8px'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'left'}
            alignItems={'center'}
            transition={'all 0.3s ease-in-out'}
            _hover={{
              backgroundColor: 'rgba(0,0,0,0.2)'
            }}
          >
            <Box
              key={meme.cover}
              as={LazyLoadImage}
              width='48px'
              height='48px'
              objectFit='cover'
              // borderRadius={'8px'}
              mr='8px'
              src={`${meme.cover}!square.small`}
              alt={meme.post.title.rendered}
            />
            <Box as='h3' fontSize={'14px'} mt={{ base: '5px', md: '0px' }}>
              <Text noOfLines={2} fontWeight={'bold'}>
                {meme.post.title.rendered}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export function MemeList(memes: Meme[], maxHeight?: string, padding?: string) {
  return (
    <Box as='ul' listStyleType='none' padding={padding || '0px'}>
      {memes.map((l, index) => (
        <Box pb={{ base: '12px', md: '30px' }} as='li' key={`${l.id}_${l.author.id}_${index}`}>
          <MemeItem {...l} />
          <Divider borderColor='border' />
        </Box>
      ))}
    </Box>
  )
}

export function MemeModal(props: MemeModalProps) {
  const { list, icon, title, visible, onClose: setShowMore, author, currentPage = 1 } = props
  const [fullList, setFullList] = useState<Meme[]>(list)
  const [page, setPage] = useState<number>(1)
  const [per_page, setPerPage] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [memeAuthor, setMemeAuthor] = useState(null)
  const handleOnClose = () => setShowMore(false)

  const fetchMoreMemes = async () => {
    setLoading(true)
    setError(null)
    const res = await fetcher(fetchMemes({ page: page + 1, per_page, author: memeAuthor || author }))
    if (res && res.posts) {
      setFullList([...fullList, ...res.posts])
      setTotal(res.total)
      setTotalPages(res.total_pages)
      setPage(page + 1)
      setPerPage(res.per_page)
      setLoading(false)
    } else {
      setError('加载失败')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentPage === 0 && visible) {
      setPage(0)
      setFullList([])
      setMemeAuthor(author)
      fetchMoreMemes()
    } else {
      setFullList([])
      setPage(0)
      setPerPage(10)
      setTotal(0)
      setTotalPages(0)
      setLoading(false)
      setError(null)
      setMemeAuthor(null)
    }

    return () => {
      setFullList([])
      setPage(0)
      setPerPage(10)
      setTotal(0)
      setTotalPages(0)
      setLoading(false)
      setError(null)
      setMemeAuthor(null)
    }
  }, [author, currentPage, visible])

  return (
    <Modal size={'xl'} isOpen={visible} onClose={handleOnClose} isCentered blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent backgroundColor={'#212832'} borderRadius={0} maxHeight={'80vh'} overflow={'auto'}>
        <ModalBody p={'0px'} height={'100%'} position={'relative'} display={'flex'} flexDir={'column'}>
          <Box
            height={'72px'}
            px={'32px'}
            display={'flex'}
            alignItems={'center'}
            position={'sticky'}
            top={'0'}
            backgroundColor={'#212832'}
          >
            <Box as='header'>
              <Text as='h2' textStyle='sectionTitle' display='flex' alignItems='center'>
                {icon && (
                  <Box mt='2px' fontSize='1em' mr='16px'>
                    {icon}
                  </Box>
                )}
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Text>
            </Box>
          </Box>
          <Box flex={1}>
            {MemeList(fullList, '70vh', '0 32px')}
            {loading ? (
              <Box textAlign='center' pb='24px' fontSize={'14'} opacity='.2'>
                <Text>加载中...</Text>
              </Box>
            ) : (
              <>
                {error && <Box>发生了错误 {total}</Box>}
                {page !== totalPages && page < totalPages ? (
                  <Box textAlign={'center'} pb={'24px'}>
                    <Box
                      as='button'
                      onClick={() => fetchMoreMemes()}
                      border={'1px solid #66FCF1'}
                      color={'#66FCF1'}
                      padding={'4px  8px'}
                      width={'60%'}
                      transition={'all 0.3s ease-in-out'}
                      _hover={{
                        backgroundColor: '#66FCF1',
                        color: '#000'
                      }}
                    >
                      加载更多
                    </Box>
                  </Box>
                ) : (
                  <Box textAlign='center' pb='24px' fontSize={'14'} opacity='.2'>
                    <Text>————没有更多了————</Text>
                  </Box>
                )}
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function pTriggerButton(props: any, ref: LegacyRef<HTMLDivElement>) {
  return (
    <Box ref={ref} {...props}>
      {props.children}
    </Box>
  )
}

export const PopoverTriggerButton = forwardRef(pTriggerButton)

export function MemeSection(props: MemeSectionProps) {
  const { list, icon, title } = props
  const [showMore, setShowMore] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  }) // 用于判断是否是移动端

  const SectionHeader = (
    <Box
      as='header'
      position={'relative'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Text as='h2' textStyle='sectionTitle' display='flex' alignItems='center'>
        {icon && (
          <Box mt='2px' fontSize='1em' mr='16px'>
            {icon}
          </Box>
        )}
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Text>
      <Box position={'relative'}>
        <Popover placement='bottom-end' isLazy>
          <PopoverTrigger>
            <PopoverTriggerButton aria-label='新功能介绍' type='button' role='button'>
              <Info width={'16px'} height={'16px'} fill={'#ccc'} />
            </PopoverTriggerButton>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor={'#e2e8f0'}
            borderRadius={0}
            borderColor={'#E2E8F0'}
            color={'#212832'}
            width={{ base: '320px', md: '320px' }}
            outline={'none'}
          >
            {/* <PopoverArrow backgroundColor={'#212832'} /> */}
            <PopoverCloseButton />
            <PopoverHeader fontSize={'14px'} fontWeight='bold'>
              新功能介绍
            </PopoverHeader>
            <PopoverBody fontSize={'14px'} paddingY={'16px'}>
              <Box as={'p'} marginBottom={'12px'}>
                36氪出海紧贴行业发展，为出海人有机整合原创深度报道、行业资讯和观点，输出高品质内容。这是一个跟行业交流意见的过程，而36氪出海的分析师和运营同学，第一位的身份，是行业的长期观察者。
              </Box>
              <Box as={'p'} marginBottom={'12px'}>
                新推出的“领读”功能，为的是提炼、前置我们的视角或观点，帮出海人更加聚焦文章在出海语境下的核心价值。
              </Box>
              <Box as={'p'}>欢迎您通过“出海小助手”（ID: wow36krchuhai-xzs）提供宝贵的改进意见。</Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  )

  return (
    <Box overflowX={'hidden'}>
      <Box mb={'24px'} display={'flex'} alignItems={'center'}>
        {SectionHeader}
      </Box>
      <Box height={{ base: '100%', md: '480px' }} overflow={'auto'}>
        {MemeList(list)}
      </Box>
      <Box
        textStyle='sectionArticleSeeAll'
        alignItems='center'
        display='flex'
        pt='20px'
        borderColor='seeAllBorder'
        justifyContent='flex-end'
        onClick={() => {
          if (!isMobile) {
            setShowMore(!showMore)
          } else {
            router.push(`/memes`)
          }
        }}
        cursor='pointer'
      >
        全部
        <Box
          ml='14px'
          fontSize={{
            base: '30px'
          }}
        >
          <SeeAll />
        </Box>
      </Box>

      {MemeModal({
        list,
        title,
        icon,
        visible: showMore,
        onClose(status) {
          setShowMore(status || false)
        },
        currentPage: 0
      })}
    </Box>
  )
}
