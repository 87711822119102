import React from 'react'
import { Box, BoxProps } from '../Box'

export type AvatarProps = BoxProps & {
  alt?: string
  src?: string
  size?: number
}

export function Avatar(props: AvatarProps) {
  const { alt, size = 180, src, ...otherProps } = props
  return (
    <Box width={size} height={size} {...otherProps}>
      <Box width={'100%'} height={'100%'} objectFit='cover' borderRadius='50%' as='img' src={src} alt={alt} />
    </Box>
  )
}
