import * as React from 'react'

function SeeAll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 23 22' fill='none' {...props}>
      <path
        d='M12.155.248c.39.047.785.08 1.176.141 3.394.555 6.03 2.212 7.895 4.946 1.268 1.857 1.83 3.916 1.77 6.125-.13 4.935-3.938 9.271-9.04 10.294C7.88 22.976 1.968 19.538.375 13.856-1.285 7.94 2.723 1.768 9.06.503 9.596.397 10.144.35 10.688.277c.057-.007.11-.022.168-.029h1.299zm-.651 1.258c-5.609 0-10.156 4.3-10.167 9.613-.008 5.312 4.547 9.63 10.167 9.63 5.608 0 10.158-4.3 10.166-9.612.008-5.312-4.55-9.63-10.167-9.63z'
        fill='currentColor'
      />
      <path
        d='M11.504 1.506c5.615 0 10.174 4.318 10.166 9.63-.008 5.313-4.558 9.613-10.167 9.613-5.615 0-10.174-4.319-10.166-9.63.011-5.313 4.558-9.613 10.167-9.613zm2.804 9.58v.083c-.066.04-.138.073-.192.123a2567.705 2567.705 0 00-4.658 4.41c-.054.05-.111.101-.157.16-.268.34-.107.826.318.964.295.094.532-.004.743-.203 1.747-1.657 3.494-3.307 5.24-4.96.391-.37.391-.693 0-1.063-1.735-1.65-3.474-3.296-5.213-4.939-.046-.043-.088-.087-.138-.123-.368-.283-.916-.12-1.05.312-.084.275.027.49.23.685 1.563 1.476 3.126 2.955 4.689 4.435.054.043.126.076.188.116z'
        fill='#1F2833'
      />
      <path
        d='M14.308 11.087c-.065-.04-.138-.072-.192-.12-1.559-1.479-3.122-2.958-4.685-4.434-.203-.192-.314-.406-.23-.685.135-.432.682-.592 1.05-.312.05.036.092.083.138.123 1.74 1.646 3.478 3.289 5.214 4.935.39.37.39.693 0 1.062l-5.24 4.96c-.211.2-.449.298-.744.204-.425-.134-.586-.62-.318-.965.046-.058.1-.109.157-.16l4.658-4.409c.054-.05.127-.08.192-.123v-.076z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SeeAll
